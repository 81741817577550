import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router';
import Icon from '../icon/icon';
import './Header.sass'

const Header = () => {
  const location = useLocation();
  return (
    <Navbar className='header'  expand="lg" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">
          <img alt='logo' src={require('../../static/logo3.jpeg')} height={100} />
        </Navbar.Brand>
        <Nav.Link href='tel:+79160800015' className='phone'>{window.innerWidth>430?'+7 (916) 080-00-15':<Icon className='phoneIcon' size={35} name={"phone"}/>}</Nav.Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        
        <Navbar.Collapse className="justify-content-end" id="navbarScroll">
          <Nav
            activeKey={location.pathname}
          >
            <Nav.Link href='/'>ГЛАВНАЯ</Nav.Link>
            <Nav.Link href='/about-us'>О НАС</Nav.Link>
            <Nav.Link href='/media'>МЕДИА</Nav.Link>
            <Nav.Link href='/shop'>ФИРМЕННЫЙ МАГАЗИН</Nav.Link>
            <Nav.Link href='/contacts'>КОНТАКТЫ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

    </Navbar>
  );
}

export default Header;