import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import FullScreenImg from "../shop/fullScreenImg";

const MediaItem: FC<any> = ({ item, index, setSelectedIndex }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Card
                text='white'
                style={{ width: '18rem',padding:'5px', backgroundColor:'transparent', cursor:'pointer' }}
              onClick={()=>setSelectedIndex(index)}
            >
                <Card.Img variant="top" className="cardImg" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${item.attributes.photo.data.attributes.url}`} />
                <Card.Body>
                    <Card.Text>
                        <ReactMarkdown children={item.attributes.description ?? ''} />
                    </Card.Text>
                </Card.Body>
            </Card>
            {/* <FullScreenImg
                show={showModal}
                setShow={setShowModal}
                image={item.attributes.photo.data.attributes.url}
            /> */}
        </>
    );
}

export default MediaItem;