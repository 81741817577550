import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import './AboutUs.sass'
type AboutUs = {
    id: number,
    attributes: Attributes
}
type Attributes = {
    aboutCompany: string,
    aboutCompanyImg
    :
    { data: ImgType[] }
    createdAt: string,
    founder1: string,
    founder1img
    :
    { data: ImgType[] }
    founder2: string,
    founder2img
    :
    { data: ImgType[] }
    publishedAt: string,
    title: string,
    updatedAt: string,
}
type ImgType = {
    id: number,
    attributes: {
        url: string
    }
}
const AboutUs = () => {
    const [items, setItems] = useState<AboutUs>()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${process.env.REACT_APP_STRAPI_URL}about-us?populate=deep`,
                );
                setItems(result.data.data);
            } catch (error) { toast.error("Something went wrong, try again later") }
        }
        fetchData();
        setLoading(false);
    }, []);

    return (
        <>
            {!!loading ? <Loader /> :
                <>
                    <h2>{items?.attributes.title.toUpperCase()}</h2>
                    <Card className="aboutUsCard" bg={'dark'}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Card.Img variant="left" style={{maxHeight:'350px', width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_STRAPI_IMG_URL}${items?.attributes.aboutCompanyImg.data[0].attributes.url}`} />
                            </Col>
                            <Col>
                                <Card.Body>
                                    <ReactMarkdown children={items?.attributes.aboutCompany ?? ''} />
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="aboutUsCard" bg={'dark'}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Card.Img style={{maxHeight:'350px', width: "100%", height: "100%", objectFit: "contain" }}  variant="left" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${items?.attributes.founder1img.data[0].attributes.url}`} />
                            </Col>
                            <Col>
                                <Card.Body>
                                    <ReactMarkdown children={items?.attributes.founder1 ?? ''} />
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="aboutUsCard" bg={'dark'}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Card.Img style={{maxHeight:'350px', width: "100%", height: "100%", objectFit: "contain" }}  variant="left" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${items?.attributes.founder2img.data[0].attributes.url}`} />
                            </Col>
                            <Col>
                                <Card.Body>
                                    <ReactMarkdown children={items?.attributes.founder2 ?? ''} />
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </>
            }
        </>
    );
}

export default AboutUs;