import { Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import './Contacts.sass'
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from "react-toastify";
import * as Yup from "yup";

const CallBackForm = () => {
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const CallBackValidationSchema = Yup.object().shape({
        FIO: Yup.string().required("Поле обязательно для заполнения"),
        phone: Yup.string().matches(phoneRegExp, 'Некорректный номер телефона'),
        message: Yup.string().required("Поле обязательно для заполнения"),
        email: Yup.string().matches(emailRegExp, 'Некорректный адрес электронной почты'),
    });


    const sendMessage = async (values: any) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "data": {
                "name": values.FIO,
                "email": values.email,
                "phone": values.phone,
                "match": values.match,
                "message": values.message,
            }
        });
        let text = `Новое сообщение! 
        От ${values.FIO}, почта: ${values.email}, телефон:${values.phone}, 
        узнали о нас:${values.match}, 
        сообщение:${values.message}`
        let telegramMessage = JSON.stringify({
            "text": text,
            "chat_id": "-640106105"
        })
        let telegramReqOptions = {
            method: 'POST',
            headers: myHeaders,
            body: telegramMessage,
        };
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(`${process.env.REACT_APP_STRAPI_URL}incoming-messages`, requestOptions)
            .then(response => response.text())
            .catch(error => toast.error('Что-то пошло не так, попробуйте снова', {
                progress: undefined,
            }));

        fetch(`https://api.telegram.org/bot${process.env.REACT_APP_BOT_TOKEN}/sendMessage`, telegramReqOptions)
            .then(response => response.text())
            .catch(error => toast.error('Что-то пошло не так, попробуйте снова', {
                progress: undefined,
            }));

    }
    return (
        <Formik
            initialValues={
                {
                    email: '',
                    FIO: '',
                    phone: '',
                    message: '',
                    match: '',
                }
            }
            validationSchema={CallBackValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                try {
                    sendMessage(values)
                    setSubmitting(false);
                    toast.success(`Ваше сообщение отправлено!`, {
                        progress: undefined,
                    });
                    resetForm()
                } catch {
                    toast.error('Что-то пошло не так, попробуйте снова', {
                        progress: undefined,
                    })
                }
            }
            }
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm
            }) => (

                <Form onSubmit={handleSubmit}>
                    <div className="field">
                        <label>Имя</label>
                        <input
                            required
                            type="text"
                            name="FIO"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FIO}
                        />
                        {errors.FIO && touched.FIO && errors.FIO}
                    </div>
                    <div className="field">
                        <label>Электронная почта</label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        {errors.email && touched.email && errors.email}
                    </div>
                    <div className="field">
                        <label>Телефон</label>
                        <input
                            required
                            type="tel"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            placeholder="+7 (***) ***-**-**"
                        />
                        {errors.phone && touched.phone && errors.phone}
                    </div>
                    <div className="field">
                        <label>Откуда вы о нас узнали</label>
                        <Field as="select" name="match">
                            <option value="-"></option>
                            <option value="instagramm">Instagram</option>
                            <option value="VK">ВКонтакте</option>
                            <option value="motoraive">от Motoraive</option>
                            <option value="razvalution">от Razvalution</option>
                            <option value="other">Перешли по ссылке с другого сайта</option>
                        </Field>
                    </div>
                    <div className="field">
                        <label>Ваше сообщение</label>
                        <TextareaAutosize
                            required
                            className="textarea"
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                        />
                        {errors.message && touched.message && errors.message}
                    </div>

                    <Button
                        style={{ marginTop: '10px' }}
                        type="submit"
                        disabled={isSubmitting}
                        variant="outline-secondary"
                    >
                        Отправить
                    </Button>
                </Form>
            )}
        </Formik>
    )

}

export default CallBackForm;