import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Loader from '../../components/loader/Loader';
import './HomePage.sass'
type HomePage = {
    id: number
    attributes: Attributes
}
type Attributes = {
    buttonText: string;
    createdAt: string;
    publishedAt: string;
    tagline: string;
    title: string;
    updatedAt: string;
}


const HomePage = () => {
    const [items, setItems] = useState<HomePage>()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${process.env.REACT_APP_STRAPI_URL}home-page`,
                );
                setItems(result.data.data);
            } catch (error) { alert("Something went wrong, try again later") }
        }
        fetchData();
        setLoading(false);
    }, []);
    return (
        <>
            {!!loading ? <Loader /> :
                <Container className='d-flex justify-content-center homePageContainer'>
                    <h1 className='title' >{items?.attributes.tagline.toLocaleUpperCase()}</h1>

                    <Button
                        variant="secondary"
                        className='butttonToShop'
                        onClick={() => window.open('/shop')}>
                        {items?.attributes.buttonText.toLocaleUpperCase()}
                    </Button>
                </Container>}
        </>
    );
}

export default HomePage;