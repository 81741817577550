import { FC } from "react";
import { Carousel, Modal } from "react-bootstrap";

const FullScreenImg: FC<any> = ({ show, setShow, image, images, index }) => {
    console.log(image)
    return (
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <Carousel defaultActiveIndex={index}>
                    {!!image && (
                        <img
                            style={{ width: "100%", height: "100%", objectFit: "contain", cursor: "pointer" }}
                            alt="goods" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${image}`} />
                    )}
                    {!image && images?.map((image: any) =>
                        <Carousel.Item interval={3000} key={image.id}>
                            <img
                                style={{ width: '90vw', height: '90vh', display: 'block', objectFit: 'contain', alignContent: 'center', objectPosition: 'center' }}
                                alt="goods" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${image.attributes.photo.data.attributes.url}`} />
                        </Carousel.Item>)}
                </Carousel>
            </Modal.Body>
        </Modal>

    );
}

export default FullScreenImg;