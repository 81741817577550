import React, { FC } from 'react';
import { Table } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Datum, ProductInCart } from '../../types';
import Icon from '../icon/icon';
import { deleteItemsFromCart } from '../redux/cart/reducer';
import './Modal.sass'
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

interface ModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    products: ProductInCart[];
}



const ProductModal: FC<ModalProps> = ({ show, setShow, products }) => {
    const productsInCart = useSelector((state: any) => state.cart.itemsInCart)
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();
    const handleClick = (product: any) => {
        return (event: React.MouseEvent) => {
            dispatch(deleteItemsFromCart(product.id))
            event.preventDefault();
        }
    }
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const CallBackValidationSchema = Yup.object().shape({
        FIO: Yup.string().required("Поле обязательно для заполнения"),
        phone: Yup.string().matches(phoneRegExp, 'Некорректный номер телефона'),
        address: Yup.string().required("Поле обязательно для заполнения"),
        email: Yup.string().matches(emailRegExp, 'Некорректный адрес электронной почты'),
    });
    let text = productsInCart.map((product: ProductInCart) => {
        return {
            "Наименование: ": product.attributes?.title,
            //@ts-ignore
            "Цвет: ": product.attributes.color_to_images.data.find((q: any) => q.id == product?.selectedVariant).attributes.color,
            "Количество: ": product.count
        }
    })



    const sendOrder = async (values: any) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let orderForStrapi = JSON.stringify({
            "data": {
                "name": values.FIO,
                "email": values.email,
                "phone": values.phone,
                "address": values.address,
                "order": productsInCart.map((product: ProductInCart, index: number) => productToString(product, index)).join(""),
                "summ": `${productsInCart.reduce((acc: number, cur: any) => acc + cur.attributes.price * cur.count, 0)} руб`
            }
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: orderForStrapi,
        };

        fetch(`${process.env.REACT_APP_STRAPI_URL}new-orders`, requestOptions)
            .then(response => response.text())
            .catch(error => toast.error('Что-то пошло не так, попробуйте снова', {
                progress: undefined,
            }));


        let text = `НОВЫЙ ЗАКАЗ!
        От: ${values.FIO}, ${values.phone}, ${values.email}, ${values.address}
        Заказ:
        ${productsInCart.map((product: ProductInCart, index: number) => productToString(product, index)).join("")}
     
        Сумма:  ${productsInCart.reduce((acc: number, cur: any) => acc + cur.attributes.price * cur.count, 0)} руб.
        `
        
        let orderForTelegram = JSON.stringify({
            "text": text,
            "chat_id": process.env.REACT_APP_CHAT_ID,
            "parse_mode": "markdown"
        })
        let telegramReqOptions = {
            method: 'POST',
            headers: myHeaders,
            body: orderForTelegram,
        };

        fetch(`https://api.telegram.org/bot${process.env.REACT_APP_BOT_TOKEN}/sendMessage`, telegramReqOptions)
            .then(response => response.text())
            .catch(error => toast.error('Что-то пошло не так, попробуйте снова', {
                progress: undefined,
            }));
    }

    const productToString = (product: ProductInCart, index: number): string => {
        return `${index + 1}) Наименование: ${product.attributes?.title ?? "Не задано"},
        Цвет: ${product.attributes?.color_to_images?.data?.find((q: Datum) => q.id.toString() == product.selectedVariant)?.attributes?.color ?? "Не задан"},
        Количество: ${product.count}.
        `
    }

    return (
        <>
            <Formik
                initialValues={
                    {
                        email: '',
                        FIO: '',
                        phone: '',
                        address: ''
                    }
                }

                validationSchema={CallBackValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    try {
                        sendOrder(values);
                        setSubmitting(false);
                        toast.success(`Ваше сообщение отправлено!`, {
                            progress: undefined,
                        });
                        resetForm();
                        handleClose();
                    } catch {
                        toast.error('Что-то пошло не так, попробуйте снова', {
                            progress: undefined,
                        })
                    }
                }
                }
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm
                }) => (

                    <Form id="order" onSubmit={handleSubmit}>
                        <Modal
                            className='productModal'
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                        >
                            <Modal.Header closeButton>
                                ОФОРМЛЕНИЕ ЗАКАЗА
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row" style={{ marginTop: '10px', justifyContent: 'space-between' }}>
                                    <div className="col-md-5 col-12">
                                        {!productsInCart.length ? <div>Корзина пуста</div> :
                                            <div>
                                                <Table striped bordered hover variant="dark">
                                                    <thead>
                                                        <tr>
                                                            <th>Наименование</th>
                                                            <th>Цвет</th>
                                                            <th>Количество</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productsInCart.map((product: any, index: any) => (
                                                            <tr key={product.id + index}>
                                                                <td>{product?.attributes?.title}</td>
                                                                <td>{product.attributes.color_to_images.data.find((q: any) => q.id == product?.selectedVariant).attributes.color}</td>
                                                                <td>{product?.count}</td>
                                                                <td onClick={handleClick(product)} style={{ fill: '#f6f1f1', cursor: 'pointer' }}><Icon className="icon" name={"trash"} /></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td>Итого:</td>
                                                            <td>{productsInCart.reduce((acc: number, cur: any) => acc + cur.attributes.price * cur.count, 0)} руб.</td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>}
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="field">
                                            <label>Имя</label>
                                            <input
                                                required
                                                type="text"
                                                name="FIO"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.FIO}
                                            />
                                            {errors.FIO && touched.FIO && errors.FIO}
                                        </div>
                                        <div className="field">
                                            <label>Электронная почта</label>
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email && errors.email}
                                        </div>
                                        <div className="field">
                                            <label>Телефон</label>
                                            <input
                                                required
                                                type="tel"
                                                name="phone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.phone}
                                                placeholder="+7 (***) ***-**-**"
                                            />
                                            {errors.phone && touched.phone && errors.phone}
                                        </div>
                                        <>
                                            <div className="field">
                                                <label>Адрес доставки</label>
                                                <TextareaAutosize
                                                    className="textarea"
                                                    name="address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.address}
                                                />
                                                {errors.address && touched.address && errors.address}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    form="order"
                                    type='submit'
                                    disabled={!productsInCart.length}
                                    variant="secondary"
                                >
                                    Оформить покупку
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default ProductModal