import { createSlice } from "@reduxjs/toolkit";
import { ProductInCart } from "../../../types";


const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        itemsInCart: <ProductInCart[]>[]
    },
    reducers: {
        setItemsInCart(state, action) {
            const duplicate = state.itemsInCart.find(q => q.selectedVariant === action.payload.selectedVariant && q.id === action.payload.id)
            if (!!duplicate) {
                duplicate.count += action.payload.count;
            } else {
                state.itemsInCart.push(action.payload)
            }
        },
        deleteItemsFromCart(state, action) {
            state.itemsInCart.splice(state.itemsInCart.findIndex(item => item.id === action.payload), 1)
            // state.itemsInCart.filter(item => item.item.id !== action.payload)
        }
    }
})

export const { setItemsInCart, deleteItemsFromCart } = cartSlice.actions
export default cartSlice.reducer