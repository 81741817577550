import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import AboutUs from "./pages/aboutUs/AboutUs";
import Contacts from "./pages/contacts/Contacts";
import HomePage from "./pages/homePage/HomePage";
import Shop from "./pages/shop";
import './App.sass';
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import NotFound from "./components/404";
import Media from "./pages/media";


const Layout = () => {
    return (
        <>
            <Header />
            <Container>
           
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/shop' element={<Shop />} />
                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/media' element={<Media/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <ToastContainer
                    theme="dark"
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick={true}
                    pauseOnHover={true}
                    draggable={true}
                />
<div style={{height:'70px'}}></div>
            </Container>
            <Footer />

        </>
    );
}

export default Layout;