import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.sass';
import { store } from './components/redux';
import Layout from './Layout';

function App() {
  return (
    <div 
    className='app'
    style={{ backgroundImage:`url(${process.env.REACT_APP_STRAPI_IMG_URL}/uploads/background_bd8fd4d287.jpg?updated_at=2022-11-21T08:03:01.805Z)`,
    backgroundRepeat:"no-repeat",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    position:'absolute',
    width:'100%',
    height:'100%',
    overflow: 'scroll',
    }}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Layout />} />
        </Routes>
      </BrowserRouter>
    </Provider>
    </div>
  );
}

export default App;
