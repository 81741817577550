import { Container, Navbar, NavbarBrand } from "react-bootstrap"
import './Footer.sass'
const Footer = (): JSX.Element => 
<div className="fixed-bottom">  

<Navbar className="footer">
    <Container>
        <NavbarBrand  className="footerInfo" >©{new Date().getFullYear()} Pullcord </NavbarBrand>
        <div>
        <a href="https://wa.me/79160800015" target={'_blank'}><img style={{height:'35px'}} alt='logo' src={require('../../static/whatsapp.png')} height={50} /></a>
        <a href="https://vk.com/pullcord" target={'_blank'}><img alt='logo' src={require('../../static/vk.png')} height={50} /></a>
        <a href="https://instagram.com/_pullcord_?igshid=YmMyMTA2M2Y=" target={'_blank'}><img alt='logo' src={require('../../static/insta.png')} height={50} /></a>
        </div>
    </Container>
</Navbar>
</div>

export default Footer