import './Loader.sass'
const Loader = () => {
    return ( 
        <div className='loader'>
        <img className="image"  src={require('../../static/loader.png')} />
        Загрузка...
        </div>
     );
}
 
export default Loader;