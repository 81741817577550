import { FC, useEffect, useState } from "react";
import { Badge, Button, Card, CloseButton, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setItemsInCart } from "../../components/redux/cart/reducer";
import { Datum } from "../../types";
import './Shop.sass';
import Icon from '../../components/icon/icon';
import FullScreenImg from "./fullScreenImg";



const FullItem: FC<any> = ({ item, closeCard }) => {
    const [selectedVariant, setSelectedVariant] = useState<number | string | undefined>(item.attributes.color_to_images.data[0].id);
    const [selectedImage, setSelectedImage] = useState<string>(item.attributes.color_to_images.data[0].attributes?.image?.data.attributes.url);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const handleClick = () => {
        console.log(item)
        dispatch(setItemsInCart({ ...item, selectedVariant, count }))
    }

    useEffect(() => {
        if (selectedVariant === undefined) return setLoading(false);
        let image = item.attributes.color_to_images.data.filter((w: Datum) => { return w.id == selectedVariant }).map((a: any) => a.attributes?.image?.data.attributes.url)[0]
        setSelectedImage(image)
        setLoading(false)
    }, [selectedVariant])


    return (
        <>
            <Card
                bg={'dark'}
                text='white'
                className="fullItemCard"
                >
                    <div 
                    style={{display:"flex", justifyContent:"right"}}
                    onClick={()=>closeCard(null)}
                    > <CloseButton variant="black" /></div>
                <Row>
                    <Col xs={12} md={6}>
                        <Card.Img onClick={()=>setShowModal(true)} className="hover-zoom" variant="top" src={`${process.env.REACT_APP_STRAPI_IMG_URL}${selectedImage}`} />
                    </Col>
                    <Col>
                        <Card.Title>{item.attributes.title}</Card.Title>
                        <Card.Text>
                            {item.attributes.description}
                            <Form.Select
                                className='select'
                                style={{ marginBottom: "10px" }}
                                value={selectedVariant}
                                onChange={(e) => { setSelectedVariant(e.target.value) }}

                            >
                                {item.attributes.color_to_images.data.map((q: Datum) =>
                                    <option value={q.id} key={q.id}>{q.attributes?.color}</option>
                                )}

                            </Form.Select>
                            <div className='counter'>
                                <Button
                                    className='icon-btn'
                                    variant="secondary"
                                    disabled={!!(count === 1)}
                                    onClick={() => setCount(count - 1)}>
                                    <Icon name={'far fa-minus'} />
                                </Button>
                                <Badge className='badge' bg="secondary">{count}</Badge>
                                <Button
                                    className='icon-btn'
                                    variant="secondary"
                                    onClick={() => setCount(count + 1)}>
                                    <Icon name={'far fa-plus'} />
                                </Button>
                            </div>
                            <Badge style={{ width: '100%' }} className='badge' bg="secondary">Стоимость: {item.attributes.price * count} руб.</Badge>
                        </Card.Text>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <Button
                    className='toCart-btn'
                    variant="secondary"
                    onClick={handleClick}
                >
                    В корзину
                </Button>
                </div>
                    </Col>
                    
                </Row>
                
            </Card>
            <FullScreenImg 
            show={showModal}
            setShow={setShowModal}
            image={selectedImage}
            />
        </>
    );
}

export default FullItem;