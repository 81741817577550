import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import CallBackForm from "./CallBackForm";
import ReactMarkdown from "react-markdown";
type Contacts = {
    attributes: Attributes
    id: number
}
type Attributes = {
    createdAt: string
    info: string
    map: string
    title: string
    updatedAt: string
}
const Contacts = () => {
    const [items, setItems] = useState<Contacts>()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${process.env.REACT_APP_STRAPI_URL}contact`,
                );
                setItems(result.data.data);
            } catch (error) { alert("Something went wrong, try again later") }
        }
        fetchData();
        setLoading(false);
    }, []);

    return (
        <>
            <h2>{items?.attributes.title.toUpperCase()}</h2>
            <div className="row" style={{ marginTop: '10px' }}>
                {!!loading ? <Loader /> :
                    <Fragment>
                        <div className="col-md-5 col-12">
                            <h5>КАК НАС НАЙТИ</h5>
                            <div>
                                <div><ReactMarkdown children={items?.attributes.info??''}/> </div>
                                <div>
                                    <iframe
                                        style={{ width: '100%', height: '250px' }}
                                        src={items?.attributes.map}
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-12">
                            <h5>ФОРМА ОБРАТНОЙ СВЯЗИ</h5>
                            <CallBackForm />
                        </div>
                    </Fragment>
                }
            </div>
            <div style={{height:'20px'}}></div>
        </>
    );
}

export default Contacts;