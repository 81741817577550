import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Icon from "../../components/icon/icon";
import ProductModal from "../../components/modal/modal";
import { DAT } from "../../types";
import FullItem from "./FullItem";
import GoodsItem from "./GoodsItem";

const Shop = () => {
    const [items, setItems] = useState<DAT[]>()
    const [showModal, setShowModal] = useState(false);
    const [fullItemId, setFullItemId] = useState<any>(null)
    const productsInCart = useSelector((state: any) => state.cart.itemsInCart)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${process.env.REACT_APP_STRAPI_URL}goods?populate=deep`,
                );
                setItems(result.data.data);
            } catch (error) { alert("Something went wrong, try again later") }
        }
        fetchData();
    }, []);
    
    return (
        <>
            <div style={{ marginBottom: "25px" }}></div>
            <div className="sticky">
                <div
                    onClick={() => (!!productsInCart.length ? setShowModal(true) : (''))}
                    className="cart__wrapper">
                    <Icon name={'basket'} size={'40'} />
                    <div className={'cart__counter'}>
                        {productsInCart.reduce((acc: any, cur: { count: any; }) => acc + cur.count, 0)}
                    </div>
                </div>
            </div>
            {!fullItemId ?
                <Row>
                    {items?.map(item => (
                        <Col key={item.id} xs={8} md={6} lg={4} xxl={3}>
                            <GoodsItem setFullItemId={setFullItemId} item={item} />
                        </Col>
                    ))}
                </Row> :
                <Fragment>
                    {items?.filter(item => (item.id === fullItemId.id && item.attributes?.title === fullItemId.attributes.title))
                        .map(a => (
                            <Fragment key={a.id}>
                                <FullItem
                                    item={a}
                                    closeCard={setFullItemId}
                                />
                            </Fragment>
                        ))}
                </Fragment>}
            <div style={{ height: '20px' }}></div>
            <ProductModal
                show={showModal}
                setShow={setShowModal}
                products={productsInCart}
            />
        </>
    );
}

export default Shop;