import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useDispatch } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { setItemsInCart } from '../../components/redux/cart/reducer';
import { Datum } from "../../types";
import './Shop.sass'
import Badge from 'react-bootstrap/Badge';
import Icon from '../../components/icon/icon';
import FullItem from './FullItem';
import Carousel from 'react-bootstrap/Carousel';

const GoodsItem: FC<any> = ({item, setFullItemId}) => {
    const [selectedVariant, setSelectedVariant] = useState<number | string | undefined>(item.attributes.color_to_images.data[0].id);
    const [selectedImage, setSelectedImage] = useState<string| undefined>(item.attributes.color_to_images.data[0].attributes?.image?.data.attributes.url);
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(true);
 
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setItemsInCart({ ...item, selectedVariant, count }))
    }



    useEffect(() => {
        if (selectedVariant === undefined) return setLoading(false);
        let image = item.attributes.color_to_images.data.filter((w: Datum) => { return w.id == selectedVariant }).map((a: any) => a.attributes?.image?.data.attributes.url)[0]


        setSelectedImage(image)
        setLoading(false)
    }, [selectedVariant])

    return (
        <>
            <Card
            className='shortItem'
                bg={'dark'}
                text='white'
                style={window.innerWidth>395?{ width: '18rem' }:{width:'15rem'}}
                onClick={()=>setFullItemId(item)}
            >
                <Carousel>
                    {item.attributes.color_to_images.data.map((q: any) => (
                        <Carousel.Item key={q.id} interval={2000}>
                            <img
                                className="d-block w-100"
                                src={`${process.env.REACT_APP_STRAPI_IMG_URL}${q.attributes?.image?.data.attributes.url}`}
                                alt="First slide"
                            />
                        </Carousel.Item>))}
                </Carousel>
                {loading ? <Loader /> :
                    <Card.Body>
                        <Card.Title>{item.attributes.title}</Card.Title>
                    </Card.Body>
                }
            </Card>

        </>
    );
}

export default GoodsItem;