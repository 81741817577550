import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import FullScreenImg from "../shop/fullScreenImg";
import MediaItem from "./item";
import "./Media.sass"
type Media = {
    id: number
    attributes: {
        description: string
        photo: {
            data: {
                id: number
                attributes: {
                    url: string
                }
            }
        }
    }
}

const Media = () => {
    const [items, setItems] = useState<Media[]>()
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<any>('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${process.env.REACT_APP_STRAPI_URL}medias?populate=deep&pagination[pageSize]=9999`,
                );
                setItems(result.data.data);
            } catch (error) { toast.error("Something went wrong, try again later") }
        }
        fetchData();
        setLoading(false)
    }, []);
console.log(1111, selectedIndex)

    return (
        <Container className="mediaContainer">
            {!!loading ? <Loader /> :
                <Fragment>
                    {!items ? <h2>Здесь пока ничего нет</h2> :
                        <>
                            <Row>
                                {items?.map((item, index) =>
                                    <Col style={{ marginRight: "auto" }} key={item.id} xs={8} md={6} lg={4} xxl={3}
                                        onClick={() => setShowModal(true)}>
                                        <MediaItem item={item} index={index} setSelectedIndex={setSelectedIndex}/>
                                    </Col>
                                )}
                            </Row>
                        </>
                    }
 <FullScreenImg
                show={showModal}
                setShow={setShowModal}
                images={items}
                index={selectedIndex}
            />
                </Fragment>
                
            }
           
        </Container>

    );
}

export default Media;